<template>
  <div class="dark:bg-black bg-white dark:shadow-none shadow-md rounded p-10 flex sm:flex-col w-full h-full">
    <h3 class="text-2xl font-semibold ">Телеграм аккаунт</h3>
    <div class="flex my-5">
      <img class="rounded" width="148" src="../../assets/img/qr/webdeclarant_prod_bot.gif" alt="">
      <div class="px-4">
        <ul>
          <li>
            Перейдите по <a class="text-blue-500" target="_blank" href="https://t.me/webdeclarant_bot">ссылке</a>
            или QR коду в телеграм бот и нажмите начать.
          </li>
          <li>
            В меню выберите "Получить ID чата".
          </li>
          <li>
            Полученный код введите в поле ниже и нажмите "Сохранить".
          </li>
        </ul>
      </div>
    </div>


    <div class="flex">
      <input v-model="id" type="text" placeholder="Введите ID">
      <button @click="submit" class="py-3 rounded bg-blue-400 w-44 ml-5 mr-auto mt-2 text-white">
        Сохранить
      </button>
    </div>


  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  data: () => ({
    id: null
  }),
  computed:{
    ...mapGetters({
      user:'auth/user'
    })
  },
  watch:{
    user:{
      handler(nv){
        const {uf_telegram_id} = nv
        this.id = uf_telegram_id
      },
      deep:true,
      immediate:true
    },
  },
  methods: {
    submit() {
      if (Number.isNaN(+this.id)) {
        return this.$notify({
          title: 'Ошибка',
          text: 'Неверный формат ID. Значение должно быть числом',
          type: 'error'
        })
      }
      axios.post("https://declarant.by/rest/user/add-telegram", {
        id: this.id
      }).then(() => {
        this.$store.dispatch('auth/getUser')
        this.$notify({
          title: 'Успешно',
          text: 'Учетная запись привязана',
          type: 'success'
        })
      }).catch((err) => {
        this.$notify({
          title: 'Ошибка',
          text: err.response.data.message,
          type: 'error'
        })
      })
    }
  }


}
</script>
<style scoped>
input {
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  rounded bg-white dark:border-transparent border border-gray-500  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white;
}
</style>