<template>
  <div class="dark:bg-blue-900 bg-white dark:shadow-none shadow-md rounded p-10 flex sm:flex-col w-full">

    <h3 class="text-2xl font-semibold mb-2 ">Подписки</h3>


    <div class="flex flex-col ">
      <label :key="sub.title" v-for="sub in rubrics" class="inline-flex items-center mb-3">
        <input type="checkbox" :value="sub.id" @change="select(sub.id)" :checked="user.includes(sub.id)"
               class="form-checkbox"/>
        <span class="ml-2">{{ sub.name }}</span>
      </label>
    </div>

    <button class="px-5 py-3 rounded bg-blue-400 w-40 mr-auto mt-2 text-white" @click="submit">
      Сохранить
    </button>

  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EditSubscribe",
  data:()=>({
    user:[],
    rubrics:[],
  }),
  created() {
    this.getSubscription()
  },
  methods:{
    select(id) {
      if(this.user.includes(id.toString())){
        this.user = this.user.filter(i => i !== id.toString())
      }else {
        this.user.push(id.toString())
      }
    },
    getSubscription(){
      axios.get('https://declarant.by/rest/subscribe/get')
      .then(res => {
        this.rubrics = res.data.data.rubrics
        this.user = res.data.data.user
      })
      .catch(err => {
        this.$notify({
          title: 'Ошибка',
          text: err.response.data.message,
          type: 'error'
        })
      })
    },
    submit(){

      axios.post('https://declarant.by/rest/subscribe/update', {rubrics:this.user})
      .then(res=> {
        this.$notify({
          title: 'Успешно',
          text: res.data.data,
          type: 'success'
        })
        this.getSubscription()
      })
      .catch(err =>{
        this.$notify({
          title: 'Ошибка',
          text: err.response.data.message,
          type: 'error'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
