<template>
    <div class="dark:bg-black bg-white dark:shadow-none shadow-md rounded p-10 flex sm:flex-col w-full h-full">

        <div v-if="user.uf_phone_verify === '1'">
            <h3 v-if="user.uf_phone_verify === '1'" class="text-2xl font-semibold mb-10 ">Ваш номер телефона
                подтвержден</h3>
            <p class="text-6xl text-blue-500 text-center"><i class="fas fa-check-double"></i></p>
        </div>
        <div v-else>
            <h3  class="text-2xl font-semibold mb-2 ">Ваш номер телефона не подтвержден</h3>
            <input v-if="!user.personal_phone && !isSendCode" type="text" v-model.trim="phone" placeholder="Номер телефона">
            <input v-if="isSendCode" type="text" v-model.trim="code" placeholder="Код подтверждения">

            <div v-if="user.personal_phone && !isSendCode" class="info">
                <p>Код будет выслан на номер : <span class="text-blue-500">{{user.personal_phone}}</span></p>
                <p>Если номер неверный вы можете отредактировать его выше</p>
            </div>
            <button v-show="!isSendCode" class="px-5 py-3 rounded bg-blue-400 w-40 mr-auto mt-2 text-white" @click="sendCode">
                Выслать код
            </button>
            <button v-show="isSendCode" class="px-5 py-3 rounded bg-green-400 w-40 mr-auto mt-2 text-white" @click="check">
                Подтвердить
            </button>

        </div>

    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "VerifyPhone",
    data: () => ({
        phone: '',
        code: '',
        isSendCode:false
    }),
    computed: {
        user() {
            return this.$store.getters['auth/user']
        }
    },
    methods: {
        sendCode() {

            let payload = {}
            if(!this.user.personal_phone){
                payload.phone =  this.phone
            }
            axios.post('https://declarant.by/rest/user/verify-phone-send',payload).then(res => {
                this.$notify({
                    title: 'Успешно',
                    text: res.data.data,
                    type: 'success'
                })
                this.isSendCode = true
            }).catch(err => {
                this.$notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })
        },
        check(){
            axios.post('https://declarant.by/rest/user/verify-phone-check', {code:this.code})
            .then(res => {
                this.$notify({
                    title: 'Успешно',
                    text: res.data.data,
                    type: 'success'
                })
                this.$store.dispatch('auth/getUser')
            }).catch(err => {
                this.$notify({
                    title: 'Ошибка',
                    text: err.response.data.message,
                    type: 'error'
                })
            })

        }
    }
}
</script>

<style scoped>
input {
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    rounded bg-white dark:border-transparent border border-gray-500  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white;
}
.info{
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    rounded dark:bg-gray-800 bg-white dark:border-transparent border border-gray-500  dark:text-gray-300

}
</style>
