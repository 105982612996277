<template>
    <div class="dark:text-white xl:container mx-auto text-black">


        <div class="flex sm:flex-col xl:flex-row sm:mt-10 xl:mx-10">
            <div class="sm:mb-10 xl:mb-0 mx-5 flex flex-col items-baseline ">
                <div class="mb-5 flex sm:justify-center lg:justify-start sm:w-full">
                    <div class="rounded-full sm:w-48 sm:h-48 lg:w-64 lg:h-64 xl:w-72 xl:h-72 overflow-hidden">
                        <img :src="user.personal_photo" alt="@/assets/img/icons/profile.svg"
                             class=""
                             @error="replaceByDefault">
                    </div>
                </div>


                <p class="font-semibold dark:text-white text-xl mb-1">{{ user.name }} {{ user.last_name }}</p>
                <p class="text-gray-400 text-md mb-2">{{ user.login }}</p>


                <button id="edit" class="rounded border dark:border-white border-gray-300 px-3 py-1 w-full "
                        @click="edit = !edit"
                        v-show="!edit">
                    <i class="fas fa-edit"></i> Редактировать профиль
                </button>
                <div class="flex w-full" v-show="edit">
                    <button id="save"
                            class="border border-green-600 py-1 px-3 rounded mr-1 dark:text-white  text-black w-full"
                            @click="save">
                        <i class="fas fa-save"></i> Сохранить
                    </button>
                    <button id="notSave"
                            class="border border-red-600 py-1 px-3 rounded dark:text-white text-black w-full "
                            @click="notSave">
                        <i class="fas fa-times"></i> Отменить
                    </button>
                </div>
            </div>
            <div class="dark:bg-green-900 bg-white dark:shadow-none shadow-md rounded p-10 flex sm:flex-col xl:flex-row w-full">
                <div class="sm:w-full xl:w-1/2 xl:pr-5">
                    <h3 class="text-2xl font-semibold mb-2">Обо мне</h3>

                    <div class="flex whitespace-nowrap " v-for="field in fields" :key="field.value">
                        <g-emoji>{{ field.emoji }}</g-emoji>
                        <span class=" pl-2 pr-5 text-gray-400"> {{ field.name }}: </span>
                        <input type="text" v-if="!field.type" :disabled="isDisabled(field.value)"
                               :class="{'bg-transparent border-b border-dotted dark:border-gray-300 border-gray-600':!isDisabled(field.value), 'dark:bg-transparent bg-white border-b border-transparent':isDisabled(field.value)}"
                               class="block w-full  dark:text-white outline-none "
                               v-model="form[field.value] ">
                        <select v-if="field.type ==='gender'" v-model="form.personal_gender" :disabled="isDisabled"
                                class="block  dark:text-white outline-none w-full"
                                :class="{'dark:bg-green-900 bg-white border-b border-dotted dark:border-gray-300 border-gray-600':!isDisabled(field.value), 'dark:bg-green-900 bg-white border-b border-transparent':isDisabled(field.value)}"
                        >
                            <option value="" disabled selected>Выберите страну</option>
                            <option :key="g.value" :value="g.value" v-for="g in gender">{{ g.name }}</option>

                        </select>
                        <select v-if="field.type ==='countries'" v-model="form.personal_country" :disabled="isDisabled(field.value)"
                                class="block   dark:text-white outline-none w-full"
                                :class="{'dark:bg-green-900 bg-white border-b border-dotted dark:border-gray-300 border-gray-600':!isDisabled(field.value), 'dark:bg-green-900 bg-white border-b border-transparent':isDisabled(field.value)}"
                        >
                            <option value="" disabled selected>Выберите страну</option>
                            <option :key="key" :value="key" v-for="(country, key) in countries">{{
                                    country.name
                                }}
                            </option>

                        </select>

                    </div>
                </div>

                <div class="sm:w-full xl:w-1/2 sm:mt-5 xl:mt-0" v-show="edit">
                    <vueDropzone ref="photo"
                                 class="dark:bg-green-900 h-full rounded dark:hover:border-green-700 bg-white dark:border-green-800"
                                 id="dropzone" :options="dropzoneOptions"></vueDropzone>
                </div>


            </div>
        </div>

      <div class="xl:mx-1 ">
        <Documents></Documents>
      </div>
        <div class="grid sm:grid-cols-1 md:grid-cols-2  my-5  gap-5 ">
            <div class="w-full   ">
                <ChangePassword></ChangePassword>
            </div>
            <div class="w-full ">
                <VerifyPhone></VerifyPhone>
            </div>
            <div class="w-full ">
                <Subscribes></Subscribes>
            </div>
          <div class="w-full">
            <TelegramAccount></TelegramAccount>
          </div>


        </div>


    </div>
</template>

<script>
import '@github/g-emoji-element'
import {mapGetters} from "vuex";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ChangePassword from '../../components/user/ChangePassword'
import Subscribes from '../../components/user/EditSubscribe'
import VerifyPhone from "../../components/user/VerifyPhone";
import TelegramAccount from "@/components/user/TelegramAccount.vue";
import Documents from "@/components/user/Documents.vue";


export default {
    meta: {
        title: 'Редактирование'
    },
    name: "UserEdit",
    components: {
      Documents,
        VerifyPhone,
        vueDropzone: vue2Dropzone,
        ChangePassword, Subscribes,
      TelegramAccount
    },
    data: () => ({

        loading: false,
        dropzoneOptions: {
            url: 'https://declarant.by/rest/user/update',
            autoProcessQueue: false,
            thumbnailWidth: 150,
            maxFilesize: 50000000,
            maxFiles: 1,
            uploadMultiple: true,
            dictDefaultMessage: 'Выберите фотографию профиля',
            dictMaxFilesExceeded: 'Разрешен только один файл',
            acceptedFiles: 'image/*',
            addRemoveLinks:true,
            dictRemoveFile :'Удалить'
        },
        edit: false,
        gender: [
            {name: 'Мужской', value: 'M'},
            {name: 'Женский', value: 'F'}
        ],
        fields: [
            {name: 'Имя', value: 'name', emoji: '👋'},
            {name: 'Фамилия', value: 'last_name', emoji: '🙂'},
            {name: 'Отчество', value: 'second_name', emoji: '🧔'},
            {name: 'Дата рождения', value: 'personal_birthday', emoji: '📆'},
            {name: 'Пол', value: 'personal_gender', emoji: '♂', type: 'gender'},
            {name: 'Телефон', value: 'personal_phone', emoji: '📞'},
            {name: 'Адрес', value: 'personal_street', emoji: '🏠'},
            {name: 'Город', value: 'personal_city', emoji: '🌆'},
            {name: 'Область', value: 'personal_state', emoji: '🏭'},
            {name: 'Страна', value: 'personal_country', emoji: '🌍', type: 'countries'},
            {name: 'Индекс', value: 'personal_zip', emoji: '📮'},
            {name: 'Паспорт', value: 'uf_passport', emoji: '📘'},
            {name: 'Идентификационный номер', value: 'uf_person_id', emoji: '📕'},

        ],
        form: {}
    }),
    computed: {
        ...mapGetters({
            user: 'auth/user',
            countries: 'catalogs/countries'
        })
    },
    watch: {
        user: {
            handler() {
                this.setForm()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        isDisabled(type){
          return ['name', 'last_name', 'second_name'].includes(type) || !this.edit
        },
        replaceByDefault(e) {
            e.target.src = require('@/assets/img/icons/person-xl.svg')
        },
        setForm() {
            const fields = this.fields.map(f => f.value).values()
            const selected = [...fields].reduce((result, key) => {
                result[key] = this.user[key];
                return result;
            }, {});
            Object.assign(this.form, selected)
        },
        notSave() {
            this.setForm()
            this.edit = false
        },

        save() {
            let photo = this.$refs.photo.getQueuedFiles()
            let data = new FormData()
            for (let key in this.form) {
                data.append(key, this.form[key] ?? '');
            }
            data.append('image', photo[0])
            this.$store.dispatch('auth/updateUser', data)
            this.edit = false
        },
        setOK() {
            let progress = document.getElementsByClassName('dz-progress')
            for (let item of progress) {
                item.style.opacity = 0
            }
            let success = document.getElementsByClassName('dz-success-mark')
            for (let item of success) {
                item.style.opacity = 1
            }
        }
    },

}
</script>

<style scoped>
.dark .vue-dropzone:hover {
    background-color: rgb(38, 38, 38)
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

select::-webkit-scrollbar {
    display: none;
}
* >>> .dz-progress {
    display: none !important;
    opacity: 0 !important;
}
</style>
