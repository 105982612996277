<template>
  <div class="dark:bg-gray-800 bg-white dark:shadow-none shadow-md rounded p-10 flex h-full sm:flex-col w-full">

    <h3 class="text-2xl font-semibold mb-2" >Смена пароля <span class="text-xl   text-blue-400" v-tooltip="tooltip"><i class="fas fa-info " ></i></span></h3>

    <input placeholder="Старый пароль" v-model="password.old" type="password"
           >
    <small class="text-red-600"
           v-if="!$v.password.old.required && $v.password.old.$dirty">{{
        $t('forms.validation.required')
      }}</small>
    <input placeholder="Новый пароль" v-model="password.new" type="password"
         >
    <small class="text-red-600"
           v-if="!$v.password.new.required && $v.password.new.$dirty">{{
        $t('forms.validation.required')
      }}</small>
    <small class="text-red-600"
           v-if="!$v.password.new.minLength && $v.password.new.$dirty">{{
        $t('forms.validation.passMinLength')
      }}</small>
    <input placeholder="Подтверждение пароля " v-model="password.confirm" type="password">
    <small class="text-red-600"
           v-if="!$v.password.confirm.sameAsPassword ">{{
        $t('forms.validation.sameAsPassword')
      }}</small>

    <button class="px-5 py-3 rounded bg-blue-400 w-40 mr-auto mt-2 text-white" @click="submit">
      Изменить
    </button>

  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, minLength, sameAs} from 'vuelidate/lib/validators'
import axios from "axios";

export default {
  name: "ChangePassword",
  data: () => ({
    tooltip:'Пароль должен содержать латинские символы верхнего и нижнего регистра (A-z).\n' +
        'Пароль должен содержать знаки пунктуации (,.<>/?;:\'"[]{}\\|`~!@#$%^&*()-_+=).\n',
    password: {
      old: '',
      new: '',
      confirm: ''
    }
  }),
  methods: {
    submit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        axios.post('https://declarant.by/rest/user/change-password', this.password)
            .then(res => {
              this.$notify({
                title: 'Успешно',
                text: res.data.data,
                type: 'success'
              })
              this.$store.dispatch('auth/logout')
            })
            .catch(err => {
              this.$notify({
                title: 'Ошибка',
                text: err.response.data.message,
                type: 'error'
              })
            })
      }

    }

  },
  mixins: [validationMixin],
  validations: {
    password: {
      old: {required},
      new: {minLength: minLength(10), required},
      confirm: {
        sameAsPassword: sameAs(function () {
          return this.password.new
        })
      }
    }
  }

}
</script>

<style scoped>
input{
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    rounded bg-white dark:border-transparent border border-gray-500  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white;
}
</style>
